import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { trackEvent } from '../analytics';

const AnnouncementModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const hasSeenAnnouncement = localStorage.getItem('hasSeenCreditsAnnouncement');
    if (!hasSeenAnnouncement) {
      setIsOpen(true);
      trackEvent('credits_announcement_shown', {
        path: window.location.pathname,
        referrer: document.referrer
      });
    }
  }, []);

  const handleClose = (method = 'x_button') => {
    setIsOpen(false);
    localStorage.setItem('hasSeenCreditsAnnouncement', 'true');
    
    trackEvent('credits_announcement_closed', {
      close_method: method,
      path: window.location.pathname,
      time_open: Date.now() - modalOpenTime
    });
  };

  const handleCreateWorksheet = () => {
    trackEvent('credits_announcement_cta_clicked', {
      action: 'create_worksheet',
      path: window.location.pathname
    });
    handleClose('cta_button');
    window.location.href = '/worksheet-generator';
  };

  const handleMaybeLater = () => {
    trackEvent('credits_announcement_cta_clicked', {
      action: 'maybe_later',
      path: window.location.pathname
    });
    handleClose('maybe_later_button');
  };

  // Track how long the modal stays open
  const [modalOpenTime] = useState(Date.now());

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div 
        className="fixed inset-0 bg-black bg-opacity-50" 
        onClick={() => handleClose('overlay_click')} 
      />
      <div className="relative z-50 w-full max-w-lg p-6 bg-white rounded-xl shadow-xl">
        <button 
          onClick={() => handleClose('x_button')}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X size={24} />
        </button>

        <div className="space-y-4">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-900">🎉 More Worksheets Available!</h2>
            <div className="mt-2 text-lg text-gray-600">
              We've just added 3 new credits for you to generate even more custom worksheets!
            </div>
          </div>

          <div className="bg-blue-50 p-4 rounded-lg">
            <p className="text-blue-800 text-center">
              Perfect for creating additional practice materials for your students!
            </p>
          </div>

          <div className="flex flex-col items-center gap-3 pt-2">
            <button
              onClick={handleCreateWorksheet}
              className="w-full py-3 px-4 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition-colors"
            >
              Create Worksheet Now
            </button>
            <button
              onClick={handleMaybeLater}
              className="text-gray-600 hover:text-gray-800"
            >
              Maybe Later
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementModal;